<template>
  <div>
    <el-dialog title="试卷总评"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="500px"
               :before-close="handleClose">
      <el-row :gutter="10">
        <el-col :span="24"
                class="center_col">
          <span>评价:</span>
          <el-input clearable
                    placeholder="请输入试卷总评"
                    type="textarea"
                    v-model="paper_comment"
                    style="width:85%"
                    :rows="4" />
        </el-col>
      </el-row>
      <el-row style="text-align:center">
        <el-button type="primary"
                   @click="toChecktest">提交</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      paper_comment: '',
      dialogVisible: false,
      id: ''
    }
  },
  methods: {
    toChecktest () {
      this.$http({
        url: '/api/v1/papers/evaluate',
        method: 'post',
        data: {
          user_paper_id: this.id ? this.id : this.$route.query.id,
          paper_comment: this.paper_comment
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '评价成功!',
          type: 'success'
        });
        this.handleClose()
        if (this.$route.path == '/checkTestAll/changed') {
          this.handleClose()
          this.$parent.search()
        } else {
          this.$router.push('/checkTestAll/changed')
        }
      })
    },
    handleClose () {
      this.paper_comment = ''
      this.dialogVisible = false
      if (this.$route.path == '/checkTestAll/changed') {
        return
      }
      this.$router.go(-1)

    },
    getCommont (id) {
      this.$http({
        url: '/api/v1/papers/get_comment',
        method: 'get',
        params: {
          userPaperId: id ? id : this.$route.query.id
        }
      }).then(res => {
        this.paper_comment = res.data.paper_comment
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.center_col {
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    margin-right: 10px;
    font-size: 18px;
    font-weight: bold;
  }
}
.el-row {
  &:nth-child(1) {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>